import './CompanyData.scss';

import React, { useContext } from 'react';

import { Card } from 'antd';

import {
  CompanyEditingContext,
} from '../../../../../contexts/CompanyEditingContext';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { translations } from '../../../../../elements/Translation/translations';
import useCountryOptions from '../../../../../services/useCountryOptions';

export default function CompanyData(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const companyEditingContext: any = useContext(CompanyEditingContext);
  const company = companyEditingContext.savedCompany ? companyEditingContext.savedCompany.data : {};
  const { translateCountry } = useCountryOptions();

  const contactVia = () => {
    if (company.dispatchPreferences) {
      return company.dispatchPreferences.map((dispatchType: string) =>
          tl(translations.pages.contactEditing.contactSection.contactSection.dispatchTypes[dispatchType]))
        .join(", ");
    }
    return "—";
  };

  const addressComponent = (address: any, index: number) => (
    <div key={`${address.id}`}>
      <div
        className="label"
      >
        {company && company.addresses && company.addresses.length >= index && company.addresses[index - 1].forInvoice
          ? tl(translations.pages.contactDetails.contactData.fields.invoicingAddress) : tl(translations.pages.contactDetails.contactData.fields.mailingAddress)}
      </div>
      <div className="value address-value">
        <div>{`${address.street} ${address.number}`}</div>
        <div>{`${address.postalCode} ${address.city}`}</div>
        <div>{translateCountry(address.country)}</div>
      </div>
    </div>
  );
  return (
    <div className="CompanyData component">
      <div className="title">
        {tl(translations.pages.companyDetails.companyData.title)}
      </div>
      <Card className="data-card">
        <div className="basic-info section">
          <div
            className="label"
          >
            {tl(translations.pages.companyDetails.companyData.fields.name)}
          </div>
          <div className="value">
            {company.companyName ? company.companyName : '—'}
          </div>
          <div
            className="label"
          >
            {tl(translations.pages.companyDetails.companyData.fields.registerNr)}
          </div>
          <div className="value">
            {company.tradeRegisterNumber ? company.tradeRegisterNumber : '—'}
          </div>
          <div
            className="label"
          >
            {tl(translations.pages.companyDetails.companyData.fields.vatId)}
          </div>
          <div className="value">
            {company.vatId ? company.vatId : '—'}
          </div>
          <div
            className="label"
          >
            {tl(translations.pages.companyDetails.companyData.fields.website)}
          </div>
          <div className="value">
            {company.website ? company.website : '—'}
          </div>
        </div>
        <div className="address-info section">
          {company.addresses && company.addresses.map((address: any, idx: number) => addressComponent(address, idx + 1))}
          <div
            className="label"
          >
            {tl(translations.pages.contactDetails.contactData.fields.note)}
          </div>
          <div className="value">
            {company.notes ? company.notes : '—'}
          </div>
        </div>
        <div className="contact-info section">
          <div
            className="label"
          >
            {tl(translations.pages.contactDetails.contactData.fields.email)}
          </div>
          <div className="value">
            {company.email
              ? (
                <a className="value" href={`mailto:${company.email}`}>
                  {company.email}
                </a>
              )
              : '—'}
          </div>
          <div
            className="label"
          >
            {tl(translations.pages.contactDetails.contactData.fields.officeTel)}
          </div>
          <div className="value">
            {company.phoneBusiness
              ? (
                <a className="value" href={`tel:${company.phoneBusiness}`}>
                  {company.phoneBusiness}
                </a>
              )
              : '—'}
          </div>
          <div
            className="label"
          >
            {tl(translations.pages.contactDetails.contactData.fields.mobileTel)}
          </div>
          <div className="value">
            {company.phoneMobile
              ? (
                <a className="value" href={`tel:${company.phoneMobile}`}>
                  {company.phoneMobile}
                </a>
              )
              : '—'}
          </div>
          <div
            className="label"
          >
            {tl(translations.pages.contactDetails.contactData.fields.privateTel)}
          </div>
          <div className="value">
            {company.phonePrivate
              ? (
                <a className="value" href={`tel:${company.phonePrivate}`}>
                  {company.phonePrivate}
                </a>
              )
              : '—'}
          </div>
          <div
            className="label"
          >
            {tl(translations.pages.contactDetails.contactData.fields.fax)}
          </div>
          <div className="value">
            {company.fax ? company.fax : '—'}
          </div>
          <div className="value">{contactVia()}</div>
        </div>
      </Card>
    </div>
  );
}
